import { get } from "http";
import {
	GET_REQUESTS,
	GET_PENDINGS,
	GET_COMPLETED,
	GET_REVIEWS,
	GET_APPROVES,
	GET_REJECTS,
	CLEAR_TABLE,
	GET_SESSION_REPORT,
	GET_SEARCH_RESULT,
	TABLE_ERROR,
	TABLE_LOADER,
	SEARCH_GET_TRUE,
	SEARCH_API_FALSE,
	POST_FEEDBACK_FORM,
	REMOVE_FEEDBACK_ALERT,
	PDF_LOADER,
	PDF_TO_JSON_DATA,
	SEND_REVIEW_DATA,
	PDF_TO_JSON_ERROR,
	GET_ALL_USER_DATA,
	SEND_REVIEW_RESET,
    CALL_BACK_LOGS,
	EXTRACT_TRANSACTION_ERROR,
	EXTRACT_TRANSACTION_DATA,
	REDO_TRANSACTION_DOC_LOADING,
	REDO_TRANSACTION_DOC_SUCCESS,
	REDO_TRANSACTION_DOC_FAILURE,
	GET_AUTO_NAV_DATA
} from "../actions/types";

const initialState = {
	requests: [],
	pendings: [],
	completed: [],
	reviews: [],
	approved: [],
	rejects: [],
	deleted: [],
	session_report: [],
	allUserData:[],
	loading: true,
	subheading: "",
	submitFeedalert: false,
	error: {},
	limit: 0,
	limitapproves: 0,
	limitrejets: 0,
	limitrequest: 0,
	pdf_loading: true,
	sendForRev: null,
	pdfjson: {},
	pdfjsonprocessing: {},
	searchAPI: false,
	callbacklog:[],
	transactionData: {} ,// Added for transaction data
	AutoNavData:{},

	

};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case POST_FEEDBACK_FORM:
			return {
				...state,
				// submitFeedalert: true,
				submitFeedalert: payload.showAlert
			};

		case REMOVE_FEEDBACK_ALERT:
			return {
				...state,
				submitFeedalert: false,
			};
	
		case GET_ALL_USER_DATA:
			return {
				...state,
				loading: false,
				allUserData: payload.data
			};

		case GET_REQUESTS:
			return {
				...state,
				limitrequest: payload.limit,
				requests: payload.data,
				loading: false,
				subheading: "New Request",
				submitFeedalert: false,
			};

		case GET_COMPLETED:
			return {
				...state,
				completed: payload.data,
				loading: false,
				subheading: "Completed",
				submitFeedalert: false,
			};

		case GET_REVIEWS:
			return {
				...state,
				reviews: payload.data,
				loading: false,
				subheading: "Review Pending",
				submitFeedalert: false,
			};
		case GET_PENDINGS:
			console.log("gg pending " + typeof payload.data.data);
			return {
				...state,
				pendings: payload.data.data,
				limit: payload.data.limit,
				loading: false,
				subheading: "Pending",
				submitFeedalert: false,
			};
		case SEARCH_GET_TRUE:
			return {
				...state,
				searchAPI: true,
			};
		case SEARCH_API_FALSE:
			return {
				...state,
				searchAPI: false,
			};
		case GET_APPROVES:
			return {
				...state,
				approved: payload.data.data,
				limitapproves: payload.data.limit,
				loading: false,
				subheading: "Approved",
				submitFeedalert: false,
			};
		case CALL_BACK_LOGS:
			return{
             ...state,
			 callbacklog:payload.data.data,
			 limitrejets:payload.data.limit,
			 loading:false,
			 subheading:"callbacklog",
			 submitFeedalert:false
			}
		case GET_REJECTS:
			return {
				...state,
				rejects: payload.data.data,
				limitrejets: payload.data.limit,
				loading: false,
				subheading: "Rejected",
				submitFeedalert: false,
			};
		case GET_SEARCH_RESULT:
			console.log("REDsss", payload);
			return {
				...state,
				rejects: payload,
				approved: payload,
				limitrejets: payload,
				pendings: payload,
				loading: false,
				subheading: "Rejected",
				submitFeedalert: false,
			};

		case GET_SESSION_REPORT:
			return {
				...state,
				session_report: payload.data,
				loading: false,
				subheading: "New Request",
				submitFeedalert: false,
			};

		case CLEAR_TABLE:
			return {
				...state,
				requests: [],
				pendings: [],
				completed: [],
				reviews: [],
				approves: [],
				rejects: [],		
				session_report: [],
				subheading: "",
				loading: true,
			};

		case TABLE_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};

		case TABLE_LOADER:
			return {
				...state,

				loading: true,
			};

		case PDF_LOADER:
			return {
				...state,
				pdf_loading: true,
				sendForRev: "loading",
			};

		case PDF_TO_JSON_DATA:
			return {
				...state,
				pdf_loading: false,
				pdfjson: payload,
				pdfjsonprocessing: payload.data,
				sendForRev: "closed",
			};
		case SEND_REVIEW_DATA:
			return {
				...state,
				pdf_loading: false,
				pdfjson: payload,
				pdfjsonprocessing: payload.data,
				sendForRev: "pass",
			};

		case PDF_TO_JSON_ERROR:
			return {
				...state,
				pdf_loading: false,
				pdfjson: payload?.data,
				sendForRev: "failed",
			};

		case SEND_REVIEW_RESET:
			return {
				...state,
				sendForRev: "closed",
			};
        
			// New cases for transaction data
			case EXTRACT_TRANSACTION_DATA:
				return {
					...state,
					transactionData: action.payload,
					loading: false,
				};
			
			case EXTRACT_TRANSACTION_ERROR:
				return {
					...state,
					error: action.payload,
					loading: false,
				};

			  
				  case REDO_TRANSACTION_DOC_SUCCESS:
					return {
					  ...state,
					  loading: false,
					  sendForRev: "pass", // assuming success means it's ready for review
					};
			  
				  case REDO_TRANSACTION_DOC_FAILURE:
					return {
					  ...state,
					  loading: false,
					  error: payload, // handle error data
					  sendForRev: "failed", // or other logic based on failure
					};

					case GET_AUTO_NAV_DATA:
                        return {
							...state,
							AutoNavData: payload,
							loading: false // Update loading state
                        };
			
		default:
			return state;
	}
}
